import * as React from "react"
import "../components/sub.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Map from "../components/googlemap"
import PhotoMenu from "../components/photomenu"

import kv0 from "../images/about_us.png"

const AboutUs = () => (
  <Layout>
    <Seo title="会社概要 - ABOUT US" />
    <img className="img_fluid w-100 sub_kv" src={kv0} />
    <div className="container">
      <div className="title-content">
        <h1 className="main-title syouei-font-en syouei-font-bold">ABOUT US</h1>
        <h2 className="sub-title-ja syouei-font-bold">会社概要</h2>
      </div>
    </div>
    <div className="container">
      <div className="greetings">
        <div className="row">
          <div className="col-lg-3">
            <div className="item-title mb-4">代表挨拶</div>
          </div>
          <div className="col-lg-9">
            <p className="greetings_content">
              エネルギーインフラを通して社会貢献をし
              <br />
              携わる方々を笑顔にする　"ありがとう"　と言って頂ける仕事に精進していきたいと思います。
              <br />
              <br />
            </p>
            <div className="text-end">
              令和３年１２月２４日　株式会社ショウエイ　代表取締役社長　髙田祥如
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div className="container">
      <div className="d-flex justify-content-center business">
        <div className="row">
          <div className="col-lg-3">
            <div className="item-title">業務内容</div>
          </div>
          <div className="col-lg-9 text-content">
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-4">
                <div className="business-border">
                  <p className="business-title mt-3 syouei-font-regular">
                    油配管工事
                  </p>
                  <p className="mb-5">
                    主にガソリンスタンド、鉄工所、油送所等の配管を施工しています。
                    <br />
                    主は樹脂配管を融着して敷設していますが、稀に溶接での配管施工も、執り行っています。
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-4">
                <div className="business-border">
                  <p className="business-title mt-3 syouei-font-regular">
                    泡消火設備工事
                  </p>
                  <p className="mb-5">
                    ガソリンスタンドのセルフ化工事に伴い法令にて消火設備の配管を敷設しています。
                    <br />
                    主に樹脂配管ですが、鉄管の施工もあります。
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-4">
                <div className="business-border">
                  <p className="business-title mt-3 syouei-font-regular">
                    エアー配管工事
                  </p>
                  <p className="mb-5">
                    ガソリンスタンド施設内部においてのエアー設備、タイヤチェンジャーやマット洗い、洗車機、エアーチェッカー等に敷設しています。主に樹脂配管、鉄管の施工があります。
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-4">
                <div className="business-border">
                  <p className="business-title mt-3 syouei-font-regular">
                    アドブルー配管
                  </p>
                  <p className="mb-5">
                    ディーゼル車の排煙除去の液体になります、主にステンレスの配管施工になります。
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-4">
                <div className="business-border">
                  <p className="business-title mt-3 syouei-font-regular">
                    GS修繕工事
                  </p>
                  <p className="mb-5">
                    施設の内外装の修繕になります。土間は打ち替え、クラック補修、防火塀は作り替え、ボードの張替え等、内装は雨漏りの補修から、住宅設備交換クロス、ボードの張替え等行います。
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-4">
                <div className="business-border">
                  <p className="business-title mt-3 syouei-font-regular">
                    地下タンク設置工事
                  </p>
                  <p className="mb-5">
                    油施設には地下タンクが埋設してあり約４m程の穴を掘り地下タンクを設置しています、設置方法はクレーンにて吊り込み作業になります。
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-4">
                <div className="business-border">
                  <p className="business-title mt-3 syouei-font-regular">
                    タンクライニング工事
                  </p>
                  <p className="mb-5">
                    タンク内部にコーティング作業を行い地下タンクの使用年数の増加を見込む工事になります、人が内部に入る工事になるので、タンク上部に穴を開け点検用マンホールを設置します。主に溶接作業になります。
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-4 d-none d-lg-block">
                <div className="business-border"></div>
              </div>
              <div className="col-12 col-lg-6 col-xl-4 d-none d-xl-block">
                <div className="business-border"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div className="container d-block d-md-none">
      <div className="company">
        <div className="item-title">会社情報</div>
      </div>
    </div>
    <div className="company-content">
      <div className="container">
        <div className="company">
          <div className="row">
            <div className="col-lg-3 d-none d-md-block">
              <div className="item-title">会社情報</div>
            </div>
            <div className="col-lg-9">
              <div className="row company-info">
                <div className="col-lg-4 company-titele">事業所名</div>
                <div className="col-lg-8 company-overview">
                  株式会社ショウエイ
                </div>
                <div className="col-lg-4 company-titele">FAX番号</div>
                <div className="col-lg-8 company-overview">
                  042&#45;507&#45;9606
                  <biv />
                  <br />
                  &#65288;採用に関する問い合わせ&#65306;080&#45;3005&#45;2641&#12288;担当者名
                  高田&#65289;
                </div>
                <div className="col-lg-4 company-titele">事業内容</div>
                <div className="col-lg-8 company-overview">
                  &#12539;石油関連施設新設、保全、メンテナンス
                  <br />
                  &#12539;一般住宅、外構工事
                </div>
                <div className="col-lg-4 company-titele">職種</div>
                <div className="col-lg-8 company-overview">
                  油配管工、多能工、外構、施工管理
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <div className="container">
      <div className="map">
        <div className="row">
          <div className="col-lg-3">
            <div className="item-title">
              アクセス
              <br className="d-none d-md-block" />
              マップ
            </div>
          </div>
          <div className="col-lg-9">
            <Map />
            <div>
              <p className="map-text">多摩モノレール上北台駅より徒歩15分</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="syouei-sub syouei-about">
      <PhotoMenu />
    </div>
  </Layout>
)

export default AboutUs
